#login {
	.logo {
		position: absolute;
		width: 378px;
		top: 142px;
		left: 380px;
		z-index: 2;
	}

	.shape {
		position: absolute;
		width: 1678px;
		top: -18px;
		left: 279px;
		z-index: 1;
	}

	.line {
		position: absolute;
		width: 1678px;
		top: 311px;
		left: -125px;
		z-index: 1;
	}

	.eliza {
		position: absolute;
		width: 613px;
		bottom: -20px;
		left: 38px;
		z-index: 1;
	}

	.eliza-left {
		transform: translate3d(-150px, 0, 0);
		transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}

	.eliza-right {
		transform: translate3d(1300px, 0, 0);
		transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
	}

	.logo-ocb {
		position: absolute;
		width: 220px;
		bottom: 50px;
		right: 50px;
		z-index: 1;
	}

	.hg-button {
		height: 70px;
		font-size: 1.7rem;
	}
}
