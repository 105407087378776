@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("assets/fonts/fonts.css");

@layer base {
  :root {
    --color-dark-blue: #181336;
  }
}

body{
	@apply p-safe text-gray-900 antialiased select-none bg-blue-dark overflow-hidden;

	/* PWA enhancements */
	-webkit-overflow-scrolling: touch;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;

	background-image: url("assets/images/bg.jpg");
	background-size: cover;
	background-attachment: fixed;
}

/* body.danger{
	background-color: #c70f0f;
	background-image: url("assets/images/pattern.svg");
} */

/* body.soucoop{
	background-color: #00a5c6;
	background-image: url("assets/images/pattern.svg");
} */

/* body.soucoop::before{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 25px;
	background-color: #00a5c6;
	z-index: 2;
} */

/* body.desempenho{
	background-color: #00a85e;
	background-image: url("assets/images/pattern.svg");
} */

/* body.desempenho::before{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 25px;
	background-color: #00a85e;
	z-index: 2;
} */

/* body.identidade {
	background-color: #8ac75a;
	background-image: url("assets/images/pattern.svg");
}

body.pdgc{
	background-color: #f28a4a;
	background-image: url("assets/images/pattern.svg");
} */

/* body.pdgc::before{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 25px;
	background-color: #ff7300;
	z-index: 2;
} */

/* body.somoscoop{
	background-color: #61bf88;
	background-image: url("assets/images/pattern.svg");
} */

/* body.somoscoop::before{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 25px;
	background-color: #61bf88;
	z-index: 2;
} */

/* body.negocioscoop{
	background-color: #38d6d6;
	background-image: url("assets/images/pattern.svg");
} */

/* body.negocioscoop::before{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 25px;
	background-color: #38d6d6;
	z-index: 2;
} */

html, body{
	position: relative;
	width: 100vw;
	height: 100vmin;
	overflow: hidden;
  /* -webkit-overflow-scrolling: touch; */
}

#modal {
	z-index: 9999;
}

input {
	/* font-size: 16px !important; */
}

.txt-eliza {
	position: relative;
	z-index: 1;
	display: inline-block;
}
.txt-eliza::before{
	@apply bg-blue-500;
	content: " ";
	position: absolute;
	width: 105%;
	height: 18px;
	z-index: -1;
	bottom: 5px;
	right: -2px;
}

#root{
	/* border: 1px solid red; */
	width: 1920px;
	height: 1080px;
	position: absolute;
	top: 50%;
	left: 50%;
	margin: auto;
}
