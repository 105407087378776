#step8 {
	.content {
		position: absolute;
		left: 750px;
		top: 270px;
		width: 870px;
		z-index: 3;
	}

	.eliza {
		position: absolute;
		width: 929px;
		bottom: -50px;
		right: -150px;
		z-index: 1;
	}

	.elements {
		position: absolute;
		max-width: none;
		max-height: none;
		width: 150px;
		top: 40px;
		left: 240px;
	}

	.curve {
		position: absolute;
		bottom: -400px;
		left: -70%;
		width: 200%;
		transform: rotate(-25deg);
	}

	.iconPdgc {
		position: absolute;
		right: -300px;
		bottom: -360px;
		left: auto;
		width: 50%;
		z-index: 2;
	}

	.logoAvalia {
		position: relative;
		height: 180px;
		top: 50px;
	}

	.logo {
		position: absolute;
		width: 500px;
		top: 240px;
		left: -280px;
		right: 0;
		margin: 0 auto;
		z-index: 2;
	}

	.buttons {
		position: absolute;
		left: 0px;
		right: 280px;
		top: 475px;
		margin: 0 auto;
	}

	.game {
		position: absolute;
		top: 213px;
		width: 1280px;
		height: 735px;
		left: 0px;
		right: 7px;
		margin: 0 auto;
	}

	.monitor {
		position: absolute;
		height: 740px;
		bottom: 300px;
		left: 0px;
		right: 350px;
		margin: 0 auto;
	}

	.monitor-game {
		position: absolute;
		height: 1050px;
		bottom: 30px;
		left: 0px;
		right: 80px;
		margin: 0 auto;
	}
}
