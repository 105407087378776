#home {
	.logo {
		position: absolute;
		width: 380px;
		height: 380px;
		top: 62px;
		left: 350px;
		z-index: 2;
	}

	.shape {
		position: absolute;
		width: 1678px;
		top: -18px;
		left: 279px;
		z-index: 1;
	}

	.line {
		position: absolute;
		width: 1678px;
		top: 311px;
		left: -125px;
		z-index: 1;
	}

	.eliza {
		position: absolute;
		width: 742px;
		height: 742px;
		top: 185px;
		right: 5px;
		z-index: 1;
	}

	.logo-ocb {
		position: absolute;
		width: 220px;
		bottom: 50px;
		right: 50px;
		z-index: 1;
	}
}
