#step1 {
	.eliza {
		position: absolute;
		width: 729px;
		bottom: -20px;
		left: 19px;
		z-index: 2;
	}

	.eliza-sad {
		position: absolute;
		width: 955px;
		top: 290px;
		left: -94px;
		z-index: 2;
	}

	.shape {
		position: absolute;
		width: 1678px;
		top: 475px;
		left: -565px;
		transform: rotate(38deg);
	}

	.hands {
		position: absolute;
		width: 300px;
		top: 275px;
		right: 200px;
	}

	.animation {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		text-align: center;
		transform: translate3d(0, -25%, 0);
	}
}
