#locked {
	.logo {
		position: absolute;
		width: 378px;
		top: 142px;
		left: 380px;
		z-index: 2;
	}

	.shape {
		position: absolute;
		width: 1678px;
		top: -18px;
		left: 279px;
		z-index: 1;
	}

	.line {
		position: absolute;
		width: 1678px;
		top: 311px;
		left: -125px;
		z-index: 1;
	}

	.eliza {
		position: absolute;
		width: 842px;
		top: 190px;
		left: 1065px;
		z-index: 1;
	}

	.logo-ocb {
		position: absolute;
		width: 220px;
		bottom: 50px;
		right: 50px;
		z-index: 1;
	}
}
