
@font-face {
	font-family:"Intro Cond";
	src:url("Intro Cond.woff2") format("woff2"),url("Intro Cond.woff") format("woff"),url("Intro Cond.otf") format("opentype");
	font-style:normal;font-weight:400;
}

@font-face {
	font-family:"Intro Cond";
	src:url("Intro Cond Bold.woff2") format("woff2"),url("Intro Cond Bold.woff") format("woff"),url("Intro Cond Bold.otf") format("opentype");
	font-style:normal;font-weight:700;
}

@font-face {
	font-family:"Intro Cond";
	src:url("Intro Cond Black.woff2") format("woff2"),url("Intro Cond Black.woff") format("woff"),url("Intro Cond Black.otf") format("opentype");
	font-style:normal;font-weight:900;
}

@font-face {
	font-family:"Intro";
	src:url("Intro Light Regular.woff2") format("woff2"),url("Intro Light Regular.woff") format("woff"),url("Intro Light Regular.otf") format("opentype");
	font-style:normal;font-weight:100;
}

@font-face {
	font-family:"Intro";
	src:url("Intro Regular Alt.woff2") format("woff2"),url("Intro Regular Alt.woff") format("woff"),url("Intro Regular Alt.otf") format("opentype");
	font-style:normal;font-weight:400;
}

@font-face {
	font-family:"Intro";
	src:url("Intro Bold Regular.woff2") format("woff2"),url("Intro Bold Regular.woff") format("woff"),url("Intro Bold Regular.otf") format("opentype");
	font-style:normal;font-weight:700;
}
