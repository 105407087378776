#lottery {
	.eliza {
		position: absolute;
		bottom: 0px;
		left: -50px;
	}

	.logo-ocb {
		position: absolute;
		width: 220px;
		bottom: 50px;
		right: 50px;
		z-index: 1;
	}
}
